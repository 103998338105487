import { useConfig } from "../../../contexts/useConfig";
import { useViewerMode } from "../../../contexts/useViewerMode";
import { useTranslation } from 'react-i18next';

import ActionButtonViewerState from "../Action/ActionButtonViewerState";
import SelectIcon from "../../../assets/icons/select.png";
import {getPropertyOrDefault} from "../../../helpers/helpers";

export default function SelectButton() {
   const {t} = useTranslation();
   const [viewerMode, ,setViewerMode] = useViewerMode();
   const [config] = useConfig();
   const showActionIcons = getPropertyOrDefault(config["show-action-icons"], true);

   function isMoreThanOneModel() {
      let modelCount = 0;
      config.products.forEach((product) => {
         modelCount += product.variants.length;
      });
      if (modelCount > 1) return true;
   }

   return (
      isMoreThanOneModel() && (
         <ActionButtonViewerState
            label={t('selectButtonName')}
            iconImg={SelectIcon}
            className={showActionIcons ? "" : "noIcons"}
            action="select"
            active={viewerMode === "select"}
            handleStateButtonClicked={setViewerMode}
         />
      )
   );
}
