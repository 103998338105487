import { createContext, useContext, useState } from "react";
import { useLocalSessionSettings } from "./useLocalSessionSettings";
import ReactGA from "react-ga4";
import { useCurrentProduct } from "./useCurrentProduct";
import { useCurrentVariant } from "./useCurrentVariant";





const viewerModeContext = createContext();

export function useViewerMode() {
   return useContext(viewerModeContext);
}

export function ViewerModeProvider(props) {
    const [localSessionSettings, ] = useLocalSessionSettings();
    const [currentProduct, ] = useCurrentProduct();
    const [currentVariant, ] = useCurrentVariant();

    const defaultViewerMode = "explore"
    const [viewerMode, setViewerMode] = useState(defaultViewerMode);
    const [prevViewerMode, setPrevViewerMode] = useState(defaultViewerMode);

    const setViewerModeAndLocalSettings = (mode) => {
        switch(mode) {
            case "view":
               setPrevViewerMode(viewerMode)
               setViewerMode(mode);
               localSessionSettings.autoRotateAvailable = true;
               break;
            default:
               setPrevViewerMode(viewerMode)
               setViewerMode(mode);
               localSessionSettings.autoRotateAvailable = false;
               break;
        }

        ReactGA.event({
            category: "Action Button 2",
            action: `reybeam_${mode}`.toLowerCase(),
            label: (currentProduct && currentVariant) ? currentProduct.name + "|" + currentVariant["variant_name"] ?? currentVariant["variant-name"] : null
         });
    }
   return (
      <viewerModeContext.Provider value={[viewerMode, prevViewerMode, setViewerModeAndLocalSettings]}>
         {props.children}
      </viewerModeContext.Provider>
   );
}
